import React from "react";

function MenuItem({ image, name, price,about }) {
  return (
    <div className="menuItem">
      <div style={{ backgroundImage: `url(${image})` }}> </div>
      <h1> {name} </h1>
      <p style={{marginRight:20}}>{about}</p>
      <p>
        <span>&#8377;</span>{price} </p>
    </div>
  );
}

export default MenuItem;
