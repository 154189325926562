import AdvanceExcel from "../assets/advanceExcel.jpeg";
import excelVba from "../assets/excelVba.png";
import HtmlCss from "../assets/htmlCss.png";
import JavaImage from "../assets/java.jpeg";
import CsharpImage from "../assets/Csharpnet.png";
import AutolispImage from "../assets/autolisp.png";
import ReactImage from "../assets/reactjs.png";
import GitImage from "../assets/git.png";
import PythonImage from "../assets/python.jpeg";
import Datastructuremage from "../assets/datastructure.png";
import CprogrmImage from "../assets/C-Programming.jpeg";
import CplusImage from "../assets/Cplusplus.jpeg";



export const MenuList = [
  {
    name: "Advance excel",
    about : "Unlock the Full Potential of Excel: Our Advanced Excel Course empowers you to master intricate functions, advanced data analysis, and professional-grade reporting. Enhance your proficiency, streamline workflows, and become an Excel virtuoso.",
    image: AdvanceExcel,
    price: 3900,
  },
  {
    name: "Excel Vba",
    about : "Excel VBA Mastery: Dive into the world of Visual Basic for Applications (VBA) with our comprehensive course. Learn to automate tasks, create custom functions, and build powerful macros. Unlock Excel's full potential and boost your productivity.",
    image: excelVba,
    price: 4500,
  },
  {
    name: "HTML & JavaScript Essentials",
    about : "Embark on a dynamic journey into web development with our HTML and JavaScript course. Gain hands-on experience crafting web pages and interactive applications. Unlock the power to create, innovate, and shape the digital landscape.",
    image: HtmlCss,
    price: 5000,
  },
  {
    name: "Java Programming",
    about : "Discover the versatility and power of Java with our comprehensive course. From coding fundamentals to advanced application development, explore the language that fuels the digital world. Master Java and open doors to endless possibilities in software engineering.",
    image: JavaImage,
    price: 5000,
  },
  {
    name: "C# .NET Development",
    about : "Dive into the world of .NET with our comprehensive course. Explore the robust framework and tools that empower you to build dynamic and scalable applications for web, desktop, and mobile platforms. Elevate your software development skills with .NET.",
    image: CsharpImage,
    price: 5000,
  },
  {
    name: "AutoCAD AutoLISP Proficiency",
    about : "Unlock the potential of AutoLISP in AutoCAD with our specialized course. Delve into the world of automation, custom commands, and advanced scripting. Streamline your design workflows and harness the full capabilities of AutoCAD with AutoLISP mastery.",
    image: AutolispImage,
    price: 3000,
  },
  {
    name: "ReactJS Mastery",
    about : "Embark on a journey into the world of modern web development with our ReactJS course. Harness the power of this dynamic library to build interactive and responsive user interfaces. Master the art of component-based development and shape the future of web applications with ReactJS.",
    image: ReactImage,
    price: 5000,
  },
  {
    name: "Git Essentials",
    about : "Navigate the world of version control and collaborative coding with our Git course. Learn to efficiently manage projects, track changes, and collaborate seamlessly with teams. Gain the essential skills to streamline your development workflow using Git.",
    image: GitImage,
    price: 2000,
  },
  {
    name: "Python Proficiency Unleashed",
    about : "Embark on a journey into the world of programming excellence with our Python course. From the fundamentals of coding to advanced data analysis and web development, Python opens doors to endless possibilities. Join us and become a Python virtuoso.",
    image: PythonImage,
    price: 4000,
  },
  {
    name: "Data Structures & Algorithms:",
    about : "Unlock the secrets of efficient coding and problem-solving with our Data Structures and Algorithms course. Dive deep into the building blocks of software development. Gain the skills to optimize algorithms, design efficient data structures, and tackle complex coding challenges with confidence.",
    image: Datastructuremage,
    price: 4000,
  },
  {
    name: "C Programming Essentials",
    about : "Journey into the heart of computer programming with our C course. From the basics of coding to system-level development, C is the language that empowers you to build efficient software. Join us to master the foundation of programming and unleash your coding potential.",
    image: CprogrmImage,
    price: 3500,
  },
  {
    name: "C++ Programming Mastery",
    about : "Explore the power of C++ with our comprehensive course. From object-oriented programming to systems development, C++ is the versatile language that drives innovation. Join us to master C++ and unlock the door to a world of software possibilities.",
    image: CplusImage,
    price: 4000,
  },
];
