import React from "react";
import ContsctUs from "../assets/contsctUs.jpg";
import LocalPhone from "@material-ui/icons/LocalPhoneTwoTone"
import MailIcon from "@material-ui/icons/Mail"
import "../styles/Contact.css";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <div className="contact">
      <Helmet>
        <title>Coco Code Contact</title>
        <meta name="description"  content="coco code, 119, Swaraj Nagari, Talegaon Dabhade , Pune 410507"/>
      </Helmet>
      <div
        className="leftSide"
        // style={{ backgroundImage: `url(${ContsctUs})` }}
      >
              <div className="google-map">

        <iframe
          title="Google Map Location"
          src="https://maps.google.com/maps?width=800&amp;height=400&amp;hl=en&amp;q=swaraj nagari, taleagon &amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          width="800"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>

      </div>
      <div className="rightSide">
      <h1> Contact Us</h1>
      <div className="addresscontainer">
        <p className="label" htmlFor="name">Address</p>
        <p>119, Swaraj Nagari, Talegaon Dabhade , Pune 410507</p>
      </div>
        
        <div className="phoneContainer">
        <LocalPhone style={{fontSize:20,marginRight:10}} /><p>+91 7720916836</p>
        </div>
        <div className="mailcontainer">
        <MailIcon style={{fontSize:20,marginRight:10}} /><p>cococodecontact@gmail.com</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
