import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Router>
        <Helmet>
          <title>Cococ Code</title>
          <meta name="description" content="Cultivating Your Coding Skills with Industry Experts"/>
          <meta name="keywords" content="Autolisp,Javascript,Learn C#,Learn Advance excel, Talegaon dabhade,Pune,AWS"/>
        </Helmet>
        <Navbar />
        <div className="main-content">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/courses" exact component={Courses} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
        </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
