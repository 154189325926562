import React from "react";
import { MenuList } from "../helpers/MenuList";
import MenuItem from "../components/MenuItem";
import "../styles/Courses.css";
import { Helmet } from "react-helmet";

function Courses() {
  return (
    <div className="menu">
      <Helmet>
        <title>Coco Code Courses</title>
        <meta name="description" content={MenuList.map(x=>x.name).join(",")}/>
      </Helmet>
      <h1 className="menuTitle">Our Courses</h1>
      <div className="menuList">
        {MenuList.map((menuItem, key) => {
          return (
            <MenuItem
              key={key}
              image={menuItem.image}
              name={menuItem.name}
              price={menuItem.price}
              about={menuItem.about}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Courses;
