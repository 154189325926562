import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/banner.jpg";
import "../styles/Home.css";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
      <Helmet>
        <title>Coco code home</title>
        <meta name="description" content="Welcome to Coco Code, your gateway to a world of programming possibilities. Whether you're a beginner eager to start your coding journey or an experienced developer looking to enhance your skills, we're here to guide you. Our expert instructors, industry-driven courses, and hands-on approach will empower you to excel in the dynamic world of coding. Join us, and together, let's code the future" />
      </Helmet>
      <div className="headerContainer">
        <h1> Coco Code </h1>
        <p> UNLOCKING YOUR CODE TO SUCCESS</p>
        <Link to="/Courses">
          <button> ENROLL NOW! </button>
        </Link>
      </div>
      <div className="headerLeftContainer">
        <h1> Cultivating Your Coding Skills with Industry Experts</h1>
        <p> Welcome to Coco Code, your gateway to a world of programming possibilities. Whether you're a beginner eager to start your coding journey or an experienced developer looking to enhance your skills, we're here to guide you. Our expert instructors, industry-driven courses, and hands-on approach will empower you to excel in the dynamic world of coding. Join us, and together, let's code the future!"</p>
        
      </div>
    </div>
  );
}

export default Home;
