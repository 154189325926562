import React, { useState } from "react";
import Logo from "../assets/cocoCodeLogo.png";
import { Link } from "react-router-dom";
import ReorderIcon from "@material-ui/icons/Reorder";
import LocalPhone from "@material-ui/icons/LocalPhoneTwoTone"
import "../styles/Navbar.css";


function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };
  return (
    <div className="navbar">
      <div className="leftSide" id={openLinks ? "open" : "close"}>
        <img src={Logo} className="logoImage" />
        <div className="hiddenLinks">
          <Link to="/"> Home </Link>
          <Link to="/courses"> Courses </Link>
          <Link to="/about"> About </Link>
          <Link to="/contact"> Contact </Link>
         
        </div>
      </div>
      <div className="rightSide">
        <Link to="/"> Home </Link>
        <Link to="/courses"> Courses </Link>
        <Link to="/about"> About </Link>
        <Link to="/contact"> Contact </Link>
        {/* <Link to="" className="phoneContainer"><LocalPhone style={{fontSize:20,marginRight:10}} /><p>7720916836</p></Link> */}
        <button onClick={toggleNavbar}>
          <ReorderIcon />
        </button>
      </div>
    </div>
  );
}

export default Navbar;
