import React from "react";
import Logo from "../assets/cocoCodeLogo.png";
import MailIcon from "@material-ui/icons/MailOutline";
import LocalPhone from "@material-ui/icons/LocalPhoneTwoTone"
import "../styles/Footer.css";

function Footer() {
  return (<>
    <div className="footer">
      <img src={Logo} className="footerLogo" />
      <p className="mailcontainer"><MailIcon style={{fontSize:20,marginRight:10}} /><p>cococodecontact@gmail.com</p></p>
      <p className="footerPhoneConatiner"><LocalPhone style={{fontSize:20,marginRight:10}} /><p>7720916836</p></p>
      <p className="copyRightContainer"> &copy; 2023 cococode.in</p>
    </div>
    </>
  );
}

export default Footer;
