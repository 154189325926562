import React from "react";
import corporateHand from "../assets/corporateHand.jpg";
import "../styles/About.css";
import { Helmet } from "react-helmet";
function About() {
  return (
    <div className="about">
      <Helmet>
        <title>Coco Code About Us</title>
        <meta name="description" content="Coco Code is a leading destination for individuals looking to enhance their programming skills and embark on a
        journey into the world of coding excellence. Founded on a passion for technology and a commitment to education,
        Coco Code has become a trusted name in the field of programming language learning" />
      </Helmet>
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${corporateHand})` }}
      ></div>
      {/* <div className="aboutBottom">
        <h1> ABOUT US</h1>
        <p>
        Experience the power of technology and get trained to face any digital challenge with ease! At our IT training school, we provide comprehensive learning opportunities with innovative tools and techniques. Our specialized courses will teach you the latest techniques in software development, coding, web design, system administration, and more. With us, you can acquire the knowledge to excel in today’s ever-evolving tech environment. It’s time to start your journey - join our IT training school now!
        </p>
      </div> */}

      

      <div className="coco-code-details">
      <h1>About Coco Code</h1>
      <p className="mission">
        Coco Code is a leading destination for individuals looking to enhance their programming skills and embark on a
        journey into the world of coding excellence. Founded on a passion for technology and a commitment to education,
        Coco Code has become a trusted name in the field of programming language learning.
      </p>
      <h2>Our Mission</h2>
      <p>
        At Coco Code, our mission is clear: to empower individuals with the knowledge and expertise required to thrive in
        the ever-evolving tech landscape. We believe that coding is not just a skill; it's a gateway to innovation,
        problem-solving, and endless opportunities.
      </p>
      <h2>Why Choose Coco Code?</h2>
      <ul className="why-choose">
        <li>
          <strong>Industry Experts:</strong> Our courses are led by industry experts with real-world experience, ensuring
          that you learn from those who have mastered their craft.
        </li>
        <li>
          <strong>Comprehensive Curriculum:</strong> We offer a wide range of courses, from foundational programming
          languages like Python and JavaScript to advanced topics like data structures, web development, and more.
        </li>
        <li>
          <strong>Hands-On Learning:</strong> Practical, hands-on experience is at the core of our teaching approach.
          You'll apply what you learn through coding projects and real-world scenarios.
        </li>
        <li>
          <strong>Individualized Support:</strong> Our instructors are committed to your success. We provide personalized
          guidance and support throughout your learning journey.
        </li>
      </ul>
      
    </div>


    </div>
  );
}

export default About;
